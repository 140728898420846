import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imgOne from '../../images/imgOne.png';
import imgTwo from '../../images/imgTwoNova.png';

export default function IntermedioImages() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <div className="lg:w-[1000px] w-full mx-auto" id="home">
      <p id="inicio" />
      <Slider { ...settings }>
        <div>
          <img className="w-full" src={ imgTwo } alt="Imagem 2" />
        </div>
        <div>
          <img className="w-full bg-gray-50" src={ imgOne } alt="Imagem 1" />
        </div>
      </Slider>
    </div>
  );
}
