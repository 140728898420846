import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import AppContext from './AppContext';

function AppProvider({ children }) {
  const [jogos, setJogos] = useState({});
  const [horario, setHorario] = useState('12:00');
  const [open, setOpen] = useState(false);
  const [promocao, setPromocao] = useState(false);
  const [linkSite, setLinkSite] = useState('https://www.mirtilospg.com/register?id=54863402&currency=BRL&type=2');

  const values = useMemo(
    () => ({
      jogos,
      setJogos,
      horario,
      setHorario,
      linkSite,
      setLinkSite,
      open,
      setOpen,
      promocao,
      setPromocao,
    }),
    [
      jogos,
      horario,
      linkSite,
      open,
      promocao,
    ],
  );

  return (
    <AppContext.Provider value={ values }>
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
