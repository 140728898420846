/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable no-magic-numbers */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { imagesTwoo } from './components/ImagesArrPg';
import { imagesTwo } from './components/ImagesArrPragmatic';
import SelectPlataform from './components/SelectPlataform';
import IntermedioImages from './components/IntermedioImages';
import Header from './components/Header';
import AppContext from '../context/AppContext';
import { nomesJogos } from './components/Nomes';

export default function Home() {
  const { setJogos, setHorario, linkSite, open, setOpen, promocao, setPromocao } = useContext(AppContext);
  const timerRef = useRef(null);
  const hasScrolledRef = useRef(false);

  const randomNumber = Math.floor(Math.random() * 6);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      hasScrolledRef.current = true;
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setOpen(true);
        setPromocao(true);
      }, 5000);
    };
    if (!promocao) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [promocao, setOpen, setPromocao]);

  useEffect(() => {
    if (promocao) {
      clearTimeout(timerRef.current);
    }
  }, [promocao]);

  useEffect(() => {
    const time = localStorage.getItem('time-jogos');
    if (!time) {
      const date = new Date();
      const hora = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const minuto = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      localStorage.setItem('time-jogos', date.getTime());
      setHorario(`${hora}:${minuto}`);
    }
    if (time) {
      const date = new Date();
      const dateOne = Number(time);
      const dateTwo = date.getTime();
      const result = dateTwo - Number(dateOne);
      const hora = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const minuto = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      setHorario(`${hora}:${minuto}`);
      if (result > 300000) {
        localStorage.removeItem('number-jogos');
        localStorage.setItem('time-jogos', dateTwo);
        setHorario(`${hora}:${minuto}`);
      } else {
        const dateAnterior = new Date(Number(time));
        const horaAnt = dateAnterior.getHours() < 10 ? `0${dateAnterior.getHours()}` : dateAnterior.getHours();
        const minutoAnt = dateAnterior.getMinutes() < 10 ? `0${dateAnterior.getMinutes()}` : dateAnterior.getMinutes();
        setHorario(`${horaAnt}:${minutoAnt}`);
      }
    }

    const local = localStorage.getItem('number-jogos');

    if (!local) {
      const itensOne = [];
      const itensTwo = [];
      for (let i = 0; i < imagesTwo.length; i += 1) {
        const numberOne = Math.floor(Math.random() * 10);
        const numberTwo = Math.floor(Math.random() * 10);
        const number = Math.floor(Math.random() * (99 - 12 + 1)) + 12;
        itensTwo.push({ number, numberOne, numberTwo });
      }
      for (let i = 0; i < imagesTwoo.length; i += 1) {
        const numberOne = Math.floor(Math.random() * 10);
        const numberTwo = Math.floor(Math.random() * 10);
        const number = Math.floor(Math.random() * (99 - 12 + 1)) + 12;
        itensOne.push({ number, numberOne, numberTwo });
      }

      const arrayString = JSON.stringify({ itensOne, itensTwo });

      localStorage.setItem('number-jogos', arrayString);
      setJogos({ itensOne, itensTwo });
    }

    if (local) {
      const arrayRecuperado = JSON.parse(local);
      setJogos(arrayRecuperado);
    }
  }, [setJogos, setHorario, promocao, setOpen, setPromocao]);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <div className="bg-black text-white">
      <Header />
      <BootstrapDialog
        onClose={ handleClose }
        aria-labelledby="customized-dialog-title"
        open={ open }
      >
        <DialogTitle sx={ { m: 0, p: 2, fontWeight: 'bold' } } id="customized-dialog-title">
          {`🔥 Os Minutos VIP está quente para o ${nomesJogos[randomNumber]}!`}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Faça um depósito e jogue agora para aproveitar o minuto certeiro.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={ (e) => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'botao_clicado',
                categoria: 'jogo',
                nome: e, // ou qualquer outra informação relevante
              });
              window.open(linkSite, '_blank');
            } }
          >
            JOGAR
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <div className="mt-[137px] lg:mt-[170px]">
        <IntermedioImages />
      </div>
      <p className="font-custom lg:text-3xl md:text-[20px] text-[15px] text-center mt-3">
        🟢 ESSE SITE POSSUI UM SISTEMA DE ANÁLISES QUE É ATUALIZADO CONSTANTEMENTE. BOA DIVERSÃO! 🟢
      </p>
      <p className="font-custom lg:text-2xl md:text-[20px] text-[15px] text-center mt-3">
        🔴 Nossas análises são baseadas nas plataformas indicadas no site! Clique no jogo que será redirecionado 🔴
      </p>
      <p className="font-custom text-lg text-center">
        <span className="text-red-500">Atenção:</span>
        {' '}
        Proibido para menores de 18 anos.
      </p>
      <SelectPlataform />
    </div>
  );
}
