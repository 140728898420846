/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function MobileMenu({ setOpenRedes, setOpenComoFunciona }) {
  const [state, setState] = React.useState({
    right: false,
  });

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const headerHeight = document.getElementById('inicio').offsetHeight;
    if (section) {
      const offset = headerHeight; // ajuste conforme necessário
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = section.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const mapMenu = [
    { name: 'Inicio',
      nameId: 'home',
      icon: <HomeIcon />,
      path: '/' },
    {
      name: 'Como Funciona',
      nameId: 'comoFunciona',
      icon: <InfoIcon />,
      path: '/about',
    },
    {
      name: 'Minhas Redes',
      nameId: 'redes',
      icon: <PeopleAltIcon />,
      path: '/services',
    },
    {
      name: 'Sistema Vip',
      nameId: 'vip',
      icon: <StarIcon />,
      path: '/contato',
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className="bg-white h-full"
      sx={ { width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 220 } }
      role="presentation"
      onClick={ toggleDrawer(anchor, false) }
      onKeyDown={ toggleDrawer(anchor, false) }
    >
      <List>
        {mapMenu.map((text) => (
          <ListItem
            key={ text.name }
            disablePadding
            onClick={ () => {
              if (text.nameId === 'home') {
                scrollToSection(text.nameId);
              } else if (text.nameId === 'comoFunciona') {
                setOpenComoFunciona(true);
              } else if (text.nameId === 'redes') {
                setOpenRedes(true);
              } else if (text.nameId === 'vip') {
                window.open('https://t.me/slotsprime');
              }
            } }
          >
            <ListItemButton>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={ text.name } />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="lg:hidden">
      <React.Fragment key="right">
        <Button onClick={ toggleDrawer('right', true) }>
          <MenuIcon
            style={ { color: '#ffef00', fontSize: '50px' } }
          />
        </Button>
        <SwipeableDrawer
          anchor="right"
          open={ state.right }
          onClose={ toggleDrawer('right', false) }
          onOpen={ toggleDrawer('right', true) }
        >
          {list('right')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
